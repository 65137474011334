<template>
	<main>
		<form class="login" @submit.prevent="logIn" v-if="!isUserLoggedIn">
			<img class="logo" src="@/assets/images/logo.svg" alt="Landerz logo" />

			<fieldset>
				<label for="username">Username</label>
				<input id="username" v-model="loginDetails.username" aria-label="Username" required />
			</fieldset>

			<fieldset>
				<label for="password">Password</label>
				<input type="password" id="password" v-model="loginDetails.password" aria-label="Password" required />
			</fieldset>

			<button type="submit" :disabled="isSubmitting">Log In</button>
		</form>

		<form class="logout" @submit.prevent="logOut" v-if="isUserLoggedIn">
			<button type="submit">Log Out</button>
		</form>
	</main>
</template>

<script setup>
import { ref } from "vue"
import { useRouter } from "vue-router"
import { UseRootStore } from "@/model/RootStore"
import { storeToRefs } from "pinia"
import { getApiToken } from "@/utils/ApiClient"

const store = UseRootStore()
const { userApiToken, isUserLoggedIn } = storeToRefs(store)
const router = useRouter()

const loginDetails = ref({
	username: "",
	password: "",
})

const isSubmitting = ref(false)

const logIn = async () => {
	isSubmitting.value = true

	try {
		const token = await getApiToken(loginDetails.value.username, loginDetails.value.password)

		// save the api token in the root store and redirect to the home page
		store.userApiToken = token
		router.push("/")
	} catch (error) {
		console.error("Error getting auth token:", error)
		alert("Invalid username or password.")
		isSubmitting.value = false
	}
}

function logOut() {
	store.userApiToken = null
	router.push("/")
}
</script>

<style scoped>
button {
	border-radius: 8px;
	background-color: black;
	color: white;
	padding: 0.7em;
}

main {
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.login {
		transform: translateY(-10%);

		width: 360px;
		padding: 35px;
		border: 1px solid #ccc;
		border-radius: 12px;
		color: #191716;
		font-size: 180%;

		display: flex;
		flex-direction: column;

		.logo {
			margin-bottom: 36px;
			padding-left: 36px;
		}

		fieldset {
			border: initial;
			margin: initial;
			padding: initial;
			margin-bottom: 18px;

			display: flex;
			flex-direction: column;
			gap: 12px;

			label {
				color: #191716b6;
				font-family: basier_circlemedium, sans-serif;
				font-size: inherit;
			}

			input {
				padding: 0.4em;
				font-family: sans-serif;
			}
		}

		button {
			margin-top: 15px;
		}
	}

	.logout {
		transform: translateY(-100%);
		width: 360px;
		font-size: 180%;

		display: flex;

		button {
			flex: 1 1 auto;
		}
	}
}
</style>

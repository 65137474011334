<template>
	<div
		:class="{ 'right-panel': true, 'right-panel--hidden': !isOpen }"
		:style="`width: ${isOpen ? width : 0}; height: ${isOpen ? height : 0}; max-height: ${isOpen ? maxHeight : 0};`"
	>
		<div class="right-panel__toggler" @click="toggleHandler">
			<img class="right-panel__toggler-light" src="@/assets/images/menu-right/icon-close-gray.svg" alt="Icon Close" />
			<img class="right-panel__toggler-dark" src="@/assets/images/menu-right/icon-close-black.svg" alt="Icon Close" />
		</div>
		<slot></slot>
	</div>
</template>

<script setup>
const props = defineProps({
	isOpen: {
		type: Boolean,
		default: false,
	},
	width: {
		type: String,
		default: "40rem",
	},
	height: {
		type: String,
		default: "100vh",
	},
	maxHeight: {
		type: String,
		default: "100vh",
	},
})
const emit = defineEmits(["update:isOpen"])
const toggleHandler = () => {
	emit("update:isOpen", !props.isOpen)
}
</script>

<style lang="scss" scoped>
.right-panel {
	position: relative;
	padding: 1rem;
	background-color: #fff;
	opacity: 1;
	border-radius: 1.2rem;
	box-shadow: 0px 0px 6px 2px #00000026;

	transition:
		width ease-out 0.2s,
		opacity ease-out 0.2s,
		padding ease-out 0.2s;

	z-index: 1000;

	&--hidden {
		padding: 1rem 0;
		opacity: 0;

		.right-panel__toggler {
			display: none;
		}
	}

	&__toggler {
		position: absolute;
		top: 2.2rem;
		right: 2.4rem;
		width: 3.4rem;
		height: 3.4rem;
		cursor: pointer;

		img {
			position: absolute;
			width: 100%;
			transition: opacity ease-out 0.2s;
		}

		&-light {
			opacity: 0;
		}

		&:hover {
			.right-panel__toggler-dark {
				opacity: 0;
			}
			.right-panel__toggler-light {
				opacity: 1;
			}
		}
	}
}
</style>

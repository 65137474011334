export function push(blob: Blob, filename: string) {
	const url = window.URL.createObjectURL(blob)
	const link = document.createElement("a")
	link.href = url
	link.setAttribute("download", filename)

	document.body.appendChild(link)

	link.click()
	link.remove()

	window.URL.revokeObjectURL(url)
}

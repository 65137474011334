<template>
	<img :src="getFlagUrl(type, salesProcessStatus, launchDateMs)" alt="" />
</template>

<script setup>
defineProps({
	type: {
		type: String,
	},
	salesProcessStatus: {
		type: String,
		undefined: true,
	},
	launchDateMs: {
		type: Number,
		undefined: true,
	},
})

const getFlagUrl = (type, salesProcessStatus, launchDateMs) => {
	const oneMonthMs = 2592000000
	const newListing = launchDateMs && launchDateMs > Date.now() - oneMonthMs

	if (type === "landerz" && salesProcessStatus === "accepted_psa") {
		if (newListing) {
			return new URL(`/assets-flags/flag-landerz-offer-new.svg`, import.meta.url)
		} else {
			return new URL(`/assets-flags/flag-landerz-offer.svg`, import.meta.url)
		}
	}

	if (type === "landerz") {
		if (newListing) {
			return new URL(`/assets-flags/flag-landerz-new.svg`, import.meta.url)
		} else {
			return new URL(`/assets-flags/flag-landerz.svg`, import.meta.url)
		}
	}

	if (type === "off_market") {
		if (newListing) {
			return new URL(`/assets-flags/flag-off_market-new.svg`, import.meta.url)
		} else {
			return new URL(`/assets-flags/flag-off_market.svg`, import.meta.url)
		}
	}

	if (type === "external") {
		if (newListing) {
			return new URL(`/assets-flags/flag-external-new.svg`, import.meta.url)
		} else {
			return new URL(`/assets-flags/flag-external.svg`, import.meta.url)
		}
	}

	if (type === "co_listing") return new URL(`/assets-flags/flag-co_listing.svg`, import.meta.url)

	if (type === "fsbo") return new URL(`/assets-flags/flag-fsbo.svg`, import.meta.url)
}
</script>

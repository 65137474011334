<template>
	<div class="listings-filters">
		<section>
			<h5>{{ $t("filters.dev-type-title") }}</h5>
			<div class="content">
				<toggle-list v-model="activeLayers" :items="layersItems" :is-icon-active="true" />
			</div>
			<div>
				<div v-if="store.currentUser?.has(Permission.TransactionWrite)" class="content filters">
					<vue-select
						v-model="transactionTypesSelected"
						multiple
						:options="transactionTypes"
						:placeholder="$t('listings.all-dev-types')"
					>
					</vue-select>
				</div>
			</div>
		</section>
		<div class="separator"></div>

		<section>
			<h5>{{ $t("filters.others-title") }}</h5>
			<div class="content filters-range">
				<range-filter
					v-for="(filterConfig, id) in listingRangeFiltersConfig"
					:key="id"
					v-model="listingRangeFilters[id]"
					:min="filterConfig.min"
					:max="filterConfig.max"
					:step="filterConfig.step"
				>
					<template #label>
						{{ $t("listings." + id) }}
					</template>
				</range-filter>

				<div v-if="isUserLoggedIn" class="range-filter">
					<div class="range-filter__label">{{ $t("filters.data-range") }}</div>
					<div class="date-range">
						<div class="lower bound">
							<label for="min-date">{{ $t("listings.label_min") }}</label>
							<input type="date" id="min-date" v-model="minDate" />
						</div>
						<div class="upper bound">
							<label for="max-date">{{ $t("listings.label_max") }}</label>
							<input type="date" id="max-date" v-model="maxDate" />
						</div>
					</div>
				</div>
			</div>
		</section>

		<div class="separator"></div>

		<section>
			<h5>{{ $t("listings.locationAndType") }}</h5>

			<div>
				<div>
					<div class="range-filter__label">{{ $t("listings.typeDev") }}</div>

					<div class="content filters">
						<vue-select
							v-model="devTypeSelected"
							multiple
							:options="devTypeItems"
							:placeholder="$t('listings.all-dev-types')"
						>
						</vue-select>
					</div>
				</div>

				<div v-if="isUserLoggedIn && config.ui.has(Feature.Transactions)">
					<div class="range-filter__label">{{ $t("filters.municipalities-title") }}</div>

					<div class="content filters">
						<vue-select
							v-model="municipalitiesSelected"
							multiple
							:options="municipalityItems"
							:filterBy="customNormalizingFilter"
							:placeholder="$t('listings.all-cities')"
						>
						</vue-select>
					</div>

					<div class="range-filter__label">{{ $t("filters.boroughs-title") }}</div>

					<div class="content filters">
						<vue-select
							v-model="boroughsSelected"
							multiple
							:options="boroughItems"
							:filterBy="customNormalizingFilter"
							:placeholder="$t('listings.all-boroughs')"
						>
						</vue-select>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script setup>
import { config, Feature } from "@/AppConfig.ts"
import RangeFilter from "@/components/menu-left/filtering/RangeFilter.vue"
import ToggleList from "@/components/menu-left/filtering/ToggleList.vue"
import { UseRootStore, Permission } from "@/model/RootStore"
import { Borough, LandDevelopment } from "@/model/DataModel.ts"
import { getMunicipalities } from "@/utils/ApiClient"
import { storeToRefs } from "pinia"
import { computed, reactive, ref, watch, onMounted, toRaw } from "vue"
import { useI18n } from "vue-i18n"
import VueSelect from "vue-select"
import "vue-select/dist/vue-select.css"

const { superficy, constructible, price, development_type: devtypeConfig } = config.filtersDefault
const listingRangeFiltersConfig = { superficy, constructible, price }
const { t } = useI18n()
const store = UseRootStore()
const { filters: storeFilters, isUserLoggedIn } = storeToRefs(store)
const dateRange = storeFilters.value.rangeFilters.date

// format a date in YYYY-MM-DD format
function dateToExtendedISO8601(date) {
	const y = date.getFullYear()
	const m = String(date.getMonth() + 1).padStart(2, "0")
	const d = String(date.getDate()).padStart(2, "0")
	return `${y}-${m}-${d}`
}
// convert a YYYY-MM-DD string to a Date
function extendedISO8601ToDate(date) {
	const [y, m, d] = date.split("-").map(Number)
	return new Date(y, m - 1, d)
}

const minDate = ref(dateToExtendedISO8601(dateRange.min))
const maxDate = ref(dateToExtendedISO8601(dateRange.max))

let listingTypeConfig = config.filters
if (isUserLoggedIn.value && config.ui.has(Feature.Transactions)) {
	listingTypeConfig = listingTypeConfig.concat(config.restrictedFilters)
}

const layersItems = computed(() =>
	listingTypeConfig.map(id => ({
		id,
		label: t(`listings-layers.${id}`),
	}))
)
const devTypeItems = computed(() =>
	devtypeConfig.map(id => ({
		id,
		label: t(`dev-types.${id}`),
	}))
)
const listingRangeFilters = reactive({ ...listingRangeFiltersConfig })

const activeLayers = ref(listingTypeConfig)
const devTypeSelected = ref([])
// TODO unused? remove
const updatedFilters = computed(() => {
	return {
		layers: activeLayers.value,
		rangeFilters: listingRangeFilters,
		devTypes: devTypeSelected.value.length ? devTypeSelected.value.map(d => d.id) : devtypeConfig,
	}
})

const municipalities = ref([])
const boroughs = ref([])

const transactionTypes = computed(() => {
	return Object.values(LandDevelopment)
		.concat(null)
		.map(type => ({
			id: type,
			label: type ?? "undetermined", // TODO remove after 'undetermined' becomes default in db
		}))
})

const municipalitiesSelected = ref([])
const boroughsSelected = ref([])
const transactionTypesSelected = ref([])

onMounted(async () => {
	if (isUserLoggedIn.value && config.ui.has(Feature.Transactions)) {
		const fetchedMunicipalities = await getMunicipalities()

		// only store unique municipality names in the ref
		municipalities.value = [...new Set(fetchedMunicipalities.map(m => m.name))]
		boroughs.value = Object.values(Borough).sort()
	}
})

// Normalize a label by removing accents and lowercasing it
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
function normalizeLabel(name) {
	return name
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.toLowerCase()
}

const municipalityItems = computed(() =>
	municipalities.value.map(name => ({
		id: name,
		label: name,
		normalizedLabel: normalizeLabel(name),
	}))
)

const boroughItems = computed(() =>
	boroughs.value.map(name => ({
		id: name,
		label: name,
		normalizedLabel: normalizeLabel(name),
	}))
)

// Custom filter function to handle accent-insensitive matching
function customNormalizingFilter(option, label, search) {
	if (!search) return true
	return option.normalizedLabel.includes(normalizeLabel(search))
}

watch(activeLayers, layers => {
	storeFilters.value.layers = layers
})

watch(
	listingRangeFilters,
	filters => {
		storeFilters.value.rangeFilters.price = filters.price
		storeFilters.value.rangeFilters.superficy = filters.superficy
		storeFilters.value.rangeFilters.constructible = filters.constructible
	},
	{ deep: true }
)

watch(devTypeSelected, devType => {
	storeFilters.value.devTypes = devType.length ? devType.map(d => d.id) : devtypeConfig
})

watch(municipalitiesSelected, municipalityItems => {
	storeFilters.value.municipalities = toRaw(municipalityItems).map(item => item.id)
})

watch(boroughsSelected, boroughItems => {
	storeFilters.value.boroughs = toRaw(boroughItems).map(item => item.id)
})

watch(transactionTypesSelected, types => {
	storeFilters.value.transactionTypes = toRaw(types).map(item => item.id)
})

watch(minDate, min => {
	storeFilters.value.rangeFilters.date = {
		min: extendedISO8601ToDate(min),
		max: storeFilters.value.rangeFilters.date.max,
	}
})
watch(maxDate, max => {
	storeFilters.value.rangeFilters.date = {
		max: extendedISO8601ToDate(max),
		min: storeFilters.value.rangeFilters.date.min,
	}
})
</script>

<style>
.listings-filters {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: 10px;
	overflow-y: auto;
	overflow-x: hidden;
	padding-top: 22px;

	.separator {
		width: 100%;
		height: 1.5px;
		background-color: #c4c4c4;
		margin-bottom: 8px;
	}
	section {
		display: flex;
		flex-direction: column;
		gap: 15px;
		h5 {
			font-family: "basier_circlesemibold", sans-serif;
			font-size: 14px;
			font-weight: 600;
			line-height: 18px;
		}
	}
	.content {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding-left: 12px;
		padding-right: 4px;
	}
	.filters-range {
		padding: 0px;
	}

	.date-range {
		--input-width: 125px;

		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin-top: 1em;

		.bound {
			width: var(--input-width);

			.lower {
				text-transform: capitalize;
			}
			.upper {
			}
		}

		label {
			font-size: 1.2rem;
			padding-bottom: 8px;
			font-style: italic;

			&::after {
				content: ": ";
			}
		}
		input[type="date"] {
			display: flex;
			justify-content: flex-end;
			padding: 4px 8px;
			width: var(--input-width);
			border: 1px solid #ddd;

			&:focus {
				border: 2px solid #0074d9;
				outline: none;
			}
		}
	}

	.filters {
		min-height: 50px;
		font-size: 1.5rem;
		width: 32rem;
		position: relative;

		ul,
		li {
			position: relative;
		}
	}
}
</style>

<template>
	<div class="broker-card">
		<img class="broker-card-img" :src="props.picture" alt="Broker Picture" />

		<div class="broker-card-text">
			<h3 class="broker-card-name">{{ props.name }}</h3>
			<div class="broker-card-title">{{ $t("listings.broker_title") }}</div>
			<div class="broker-card-phone">
				<img
					src="@/assets/images/menu-right/icon-bullet-green.png"
					loading="lazy"
					class="green-bullet"
					width="10"
					height="10"
					sizes="(max-width: 10px) 100vw, 10px"
					alt="Bullet point"
				/>
				{{ props.phone }}
			</div>
			<div class="broker-card-email">
				<img
					src="@/assets/images/menu-right/icon-bullet-green.png"
					loading="lazy"
					class="green-bullet"
					width="10"
					height="10"
					sizes="(max-width: 10px) 100vw, 10px"
					alt="Bullet point"
				/>
				{{ props.email }}
			</div>
			<div class="broker-card-cta">
				<a
					:href="'mailto:' + props.email + '?subject=Prise de contact&body=Opportunité ' + props.hubspotid"
					target="_blank"
					>{{ $t("listings.buttonCTAOffmarket") }}</a
				>

				<svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M8.35355 4.35355C8.54882 4.15829 8.54882 3.84171 8.35355 3.64645L5.17157 0.464466C4.97631 0.269204 4.65973 0.269204 4.46447 0.464466C4.2692 0.659728 4.2692 0.976311 4.46447 1.17157L7.29289 4L4.46447 6.82843C4.2692 7.02369 4.2692 7.34027 4.46447 7.53553C4.65973 7.7308 4.97631 7.7308 5.17157 7.53553L8.35355 4.35355ZM0 4.5H8V3.5L0 3.5L0 4.5Z"
						fill="#191716"
					></path>
				</svg>
			</div>
		</div>
	</div>
</template>

<script setup>
const props = defineProps({
	name: String,
	phone: String,
	email: String,
	picture: String,
	hubspotid: String,
})
</script>

<style scoped>
.broker-card {
	border: 1px solid #c4c4c4;
	padding: 10px;
	align-items: center;
	background: #fff;
	border-radius: 11px;
	max-width: 413px;
	height: 200px;
}

.broker-card-img {
	float: left;
	height: auto;
	width: 140px;
	margin: 15px;
}

.broker-card-text {
	margin-top: 20px;
	margin-left: 180px;
	font-size: 12px;
	line-height: 17px;
	font-family:
		Basier Circle,
		sans-serif;
}

.broker-card-name {
	font-size: 20px;
	font-weight: 500;
	font-family:
		Basier Circle,
		sans-serif;
	margin: 0;
}

.broker-card-title {
	font-size: 12px;
	font-weight: 400;
	color: #777777;
	margin-top: 6px;
	margin-bottom: 17px;
}

.green-bullet {
	height: auto;
	max-width: 100%;
	width: 10px !important;
	float: left;
	margin-right: 8px;
	margin-top: 3px;
}

.broker-card-phone {
	text-transform: uppercase;
}

.broker-card-email {
	text-transform: uppercase;
}

.broker-card-cta {
	margin-top: 20px;
	background: #fff;
	border: 1px solid #191716;
	color: #191716;
	display: block;
	font-weight: 500;
	line-height: 11px;
	text-transform: uppercase;
	font-size: 11px;
	padding: 12px;
	border-radius: 2rem;
	text-align: center;
}

.broker-card-cta a {
	color: black;
	text-decoration: none;
}

.broker-card-cta a:hover {
	color: rgba(69, 231, 155, 1);
}

.broker-card-cta svg {
	margin-left: 8px;
}
</style>

import HomePage from "@/pages/HomePage.vue"
import LoginPage from "@/pages/LoginPage.vue"
import { createRouter, createWebHistory } from "vue-router"
import { appVersion } from "./AppConfig"

console.info("App version: ", appVersion)

const AppRouter = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: "/",
			name: "home",
			component: HomePage,
		},
		{
			path: "/login",
			name: "login",
			component: LoginPage,
		},
	],
})

export default AppRouter

// this *variable* will be replaced during the build by the github actions workflow
export const appVersion = "2c10a5b.1731704970"

export enum Feature {
	Transactions = "transactions",
	TransactionSourceAPI = "transaction-source-api",
	TransactionSourceTiles = "transaction-source-tiles",
}

class UI {
	private readonly features: string[]

	constructor(features: string[] = []) {
		this.features = features
	}

	has(feature: string): boolean {
		return this.features.includes(feature)
	}
}

// Available filters for referenes: ["landerz", "fsbo", "off_market", "external", "co_listing"],
const supportedRoles = [
	{
		role: "public",
		layers: [
			"cadastre",
			"administrative-divisions/regions",
			"administrative-divisions/rcms",
			"administrative-divisions/metropolitan-areas",
			"administrative-divisions/municipalities",
			"administrative-divisions/boroughs",
			"administrative-divisions/postal-codes",
			"agricultural-zones",
		],
		restrictedLayers: ["prospects", "urban-planning", "land-use", "floodable-zones", "wetlands"],
		filters: ["landerz", "off_market"],
		restrictedFilters: ["transactions"],

		ui: new UI([Feature.TransactionSourceTiles]),
	},
	{
		role: "private",
		layers: [
			"cadastre",
			"administrative-divisions/regions",
			"administrative-divisions/rcms",
			"administrative-divisions/metropolitan-areas",
			"administrative-divisions/municipalities",
			"administrative-divisions/boroughs",
			"administrative-divisions/postal-codes",
			"agricultural-zones",
		],
		restrictedLayers: ["prospects", "urban-planning", "land-use", "floodable-zones", "wetlands"],
		filters: ["landerz", "off_market"],
		restrictedFilters: ["transactions"],

		ui: new UI([Feature.Transactions, Feature.TransactionSourceTiles]),
	},
]

/// all the known devTypes supported by the app

const defaultFilters = {
	development_type: [
		"Résidentiel",
		"Mixte",
		"Commercial",
		"Lotissement",
		"Inconnu",
		"Agricole",
		"Industriel",
		"Institutionnel",
	],
	superficy: {
		min: 0,
		max: 100_000_000,
		step: 1_000,
	},
	constructible: {
		min: 0,
		max: 5_000_000,
		step: 1_000,
	},
	price: {
		min: 0,
		max: 50_000_000,
		step: 1_000,
	},
}

const envRole = import.meta.env.VITE_ROLE
const activeRole = supportedRoles.find(appRole => appRole.role === envRole)

if (activeRole === undefined) throw Error(`Unable to find configuration for APP_ROLE: ${envRole}`)

export const config = {
	role: activeRole.role,
	layers: activeRole.layers,
	restrictedLayers: activeRole.restrictedLayers,
	filters: activeRole.filters,
	restrictedFilters: activeRole.restrictedFilters,
	filtersDefault: defaultFilters,
	ui: activeRole.ui,
}

<template>
	<div class="range-selector">
		<div class="range-selector__bound">
			<label for="lower">{{ $t("listings.label_min") }}</label>
			<vue-number-input v-model="lower" :min="min" :max="max" :step="step" inline controls center></vue-number-input>
		</div>
		<div class="range-selector__bound">
			<label for="upper">{{ $t("listings.label_max") }}</label>
			<vue-number-input v-model="upper" :min="min" :max="max" :step="step" inline controls center></vue-number-input>
		</div>
	</div>
</template>

<script>
import debounce from "lodash.debounce"
import { onMounted, ref, watch } from "vue"
import VueNumberInput from "@chenfengyuan/vue-number-input"

export default {
	components: {
		VueNumberInput,
	},
	props: {
		min: {
			type: Number,
			default: 1,
		},
		max: {
			type: Number,
			default: 256,
		},
		step: {
			type: Number,
			default: 8,
		},
	},
	setup(props, { emit }) {
		const lower = ref(props.min)
		const upper = ref(props.max)

		const debouncedEmit = debounce(() => {
			const min = lower.value || 0
			const max = upper.value || 0

			emit("minmaxValue", min, max)
		}, 300)

		watch(lower, debouncedEmit)
		watch(upper, debouncedEmit)

		onMounted(() => {
			debouncedEmit()
		})

		return { lower, upper }
	},
}
</script>

<style lang="scss" scoped>
.range-selector {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	margin-top: 1em;

	&__bound {
		display: flex;
		flex-direction: column;
	}
	&__bound:first-child label {
		text-transform: capitalize;
	}
}
label {
	font-style: italic;
}
label::after {
	content: ": ";
}
</style>

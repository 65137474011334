<template>
	<div class="home-page">
		<nav>
			<a class="logo" href="https://landerz.ca">
				<img src="@/assets/images/logo.svg" alt="Landerz logo" />
			</a>

			<div class="tray">
				<a target="_blank" class="buyer-profile" href="https://go.landerz.ca/preferences-terrain">{{
					$t("buyer-profile-action")
				}}</a>

				<div class="options">
					<LangSelector />

					<div class="terms" v-if="isLargeScreen">
						<a class="terms-link" href="https://www.landerz.ca/privacypolicy" target="_blank">{{ $t("terms") }}</a>
					</div>
				</div>
			</div>
		</nav>

		<main class="home-content">
			<menu-left-layout class="home" :is-left-panel-open="mapLoaded">
				<template #left-panel>
					<template v-if="mapLoaded">
						<left-panel :title="$t('title-insc')" :subtitle="$t('subtitle-insc')">
							<left-panel-inscriptions
								:current-map-style="currentMapStyle"
								@current-map-style="handleMapStyleUpdate"
								@update-filters="updateListingFilters"
							/>
						</left-panel>
					</template>
					<template v-else>loading ...</template>
				</template>

				<template #map>
					<Map
						:listing-filters="listingFilters"
						:current-style="currentMapStyle"
						@loaded="mapLoaded = true"
						@listing-selected="listingSelected"
					/>
				</template>
			</menu-left-layout>

			<menu-right-layout v-if="isLargeScreen && visibleLayers.find(id => id === 'listings')" />

			<!-- this is the mapbox pop-up for a listing (only visible on mobile) -->
			<dialog
				v-if="!isLargeScreen"
				id="selectedListing"
				ref="selectedListingDialog"
				@cancel="closeListingDialog"
				hidden
			>
				<div id="close-action" @click="closeListingDialog"></div>
				<listing-card-detailed id="listing-card-detailed" :item="selectedListing" />
			</dialog>

			<!-- this is a warning message that this is a desktop app (only visible on mobile) -->
			<aside v-if="!isLargeScreen" class="mobile-banner">{{ mobileBannerText }}</aside>
		</main>
	</div>
</template>

<script>
import { computed, ref, inject } from "vue"
import { useI18n } from "vue-i18n"

import Map from "@/components/map/Map.vue"
import LeftPanel from "@/components/menu-left/LeftPanel.vue"
import LeftPanelInscriptions from "@/components/menu-left/panel-inscriptions/LeftPanelInscriptions.vue"
import LangSelector from "@/components/LangSelector.vue"
import { MAP_STYLES } from "@/components/constants.js"

import { config } from "@/AppConfig.ts"
import { UseRootStore } from "@/model/RootStore"
import { storeToRefs } from "pinia"

import MenuLeftLayout from "@/components/menu-left/MenuLeftLayout.vue"
import MenuRightLayout from "@/components/menu-right/MenuRightLayout.vue"
import ListingCardDetailed from "@/components/menu-right/ListingCardDetailed.vue"

export default {
	name: "HomePage",
	components: {
		LeftPanelInscriptions,
		LangSelector,
		MenuLeftLayout,
		LeftPanel,
		Map,
		MenuRightLayout,
		ListingCardDetailed,
	},
	methods: {
		closeListingDialog() {
			this.$refs.selectedListingDialog.close()
			this.$refs.selectedListingDialog.setAttribute("hidden", "")
		},
		handleMapStyleUpdate(name) {
			this.currentMapStyle = name
			window.localStorage.setItem("map-style", name)
		},
	},
	setup() {
		const store = UseRootStore()
		const { visibleLayers, loginModalVisible } = storeToRefs(store)

		const listingFilters = ref({})
		const updateListingFilters = filters => {
			listingFilters.value = { ...filters, k: Math.random() }
		}
		const mapLoaded = ref(false)

		const isLargeScreen = inject("isLargeScreen")
		const selectedListing = ref(null)
		const selectedListingDialog = ref(null)

		const { t } = useI18n()
		const mobileBannerText = computed(() => {
			return t("bannerMobileComingSoon")
		})

		const listingSelected = listing => {
			selectedListing.value = listing
			if (selectedListingDialog.value) {
				selectedListingDialog.value.showModal()
				selectedListingDialog.value.removeAttribute("hidden")
			}
		}

		const currentMapStyle = ref(window.localStorage.getItem("map-style") || MAP_STYLES.SIMPLE)

		return {
			visibleLayers,
			mapLoaded,
			listingSelected,
			selectedListing,
			selectedListingDialog,
			listingFilters,
			updateListingFilters,
			config,
			isLargeScreen,
			mobileBannerText,
			currentMapStyle,
		}
	},
}
</script>

<style lang="scss" scoped>
.home-page {
	width: 100%;
	height: 100%;

	nav {
		z-index: 10;
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		height: 3em;
		padding: 0 20px;
		box-shadow: 0 2px 18px -6px rgba(0, 0, 0, 0.26);

		.logo img {
			height: 14px;
		}

		.tray {
			font-size: 14px;

			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 0.5em;
			height: 100%;

			a.buyer-profile {
				margin: 0px 5px;
				padding: 4px 15px;
				text-decoration: none;
				color: white;
				background-color: var(--accent-color);
				font-variant: small-caps;
				font-weight: bold;
				border-radius: 15px;
			}

			.options {
				display: flex;
				gap: 10px;

				.terms a {
					padding-top: 1px;
					display: inline-block;
					text-decoration: none;
					color: inherit;
					min-width: 5em;
				}
			}
		}
	}
}

.home-content {
	width: 100%;
	height: calc(100% - 3rem);
}

.mobile-banner {
	position: fixed;
	left: 0;
	bottom: 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	width: 100%;
	margin-bottom: 0px;

	padding: 1em;
	color: white;
	font-size: 1.4em;
	font-weight: 1000;
	z-index: 10; /* show up above mapbox logo */

	background: black;
	box-shadow: 0px -1px 10px 2px #aaa;
}

dialog#selectedListing {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;

	width: 100%;
	height: 100%;
	border: none;

	--margin: 10px;

	#close-action {
		--offset: calc(var(--margin) + calc(var(--margin) / 2));
		--diameter: 40px;

		background-color: #555;
		position: absolute;
		top: var(--offset);
		right: (var(--offset));
		width: var(--diameter);
		height: var(--diameter);
		border-radius: 50%;

		display: flex;
		justify-content: center;
		align-items: center;

		&::after {
			content: "X";
			color: white;
			font-weight: bold;
			font-size: 2.5em;
		}
	}

	#listing-card-detailed {
		margin: var(--margin);
		border-radius: 5px;
	}
}
dialog:modal {
	max-width: 100vw;
	background-color: gray;
}
</style>

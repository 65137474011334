<template>
	<div :class="{ 'left-panel': true, 'left-panel--hidden': !isOpen }">
		<div
			:class="{
				'left-panel__toggler': true,
				'left-panel__toggler--hidden': !isOpen,
			}"
			@click="isOpen = !isOpen"
		>
			<img class="left-panel__toggler--light" src="@/assets/images/menu-left/icon-open-white.svg" alt="" />
			<img class="left-panel__toggler--dark" src="@/assets/images/menu-left/icon-open-black.svg" alt="" />
		</div>

		<div :class="{ 'left-panel__panel': true, hidden: !isOpen }">
			<slot>left-panel-content</slot>
		</div>
	</div>
</template>

<script setup>
import { ref } from "vue"

const props = defineProps({
	initIsOpen: {
		type: Boolean,
		default: true,
	},
})
const isOpen = ref(props.initIsOpen)
</script>

<style lang="scss" scoped>
.left-panel {
	background-color: #fff;
	border-radius: 12px;
	box-shadow: 0px 0px 6px 2px #00000026;
	padding: 17px 24px;
	max-height: calc(100% - 12px);
	overflow: hidden;
	transition: height 0.2s ease-out;
	&--hidden {
		height: 78px;
	}
	&__panel {
		width: 318px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 20px;
	}
	&__toggler {
		position: absolute;
		top: 22px;
		right: 24px;
		width: 34px;
		height: 34px;
		cursor: pointer;
		z-index: 1;
		transform: rotate(180deg);
		transition: transform 0.2s ease-out;
		img {
			position: absolute;
			transition: opacity 0.1s ease-out;
		}
		&--light {
			opacity: 0;
		}
		&:hover > & {
			&--light {
				opacity: 1;
			}
			&--dark {
				opacity: 0;
			}
		}
		&--hidden {
			transform: rotate(360deg);
			transform-origin: center;
		}
	}
}
</style>

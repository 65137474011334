<template>
	<div class="range-filter">
		<div class="range-filter__label"><slot name="label" /></div>
		<div class="range-filter__selector">
			<range-selector :min="min" :max="max" :step="step" @minmax-value="rangeSelectorUpdate" />
		</div>
	</div>
</template>

<script setup>
import RangeSelector from "@/components/menu-left/filtering/RangeSelector.vue"
const emit = defineEmits(["update:modelValue"])
defineProps({
	modelValue: Object,
	min: Number,
	max: Number,
	step: Number,
})
const rangeSelectorUpdate = (min, max) => {
	emit("update:modelValue", { min, max })
}
</script>

<style lang="scss">
.range-filter {
	padding: 4px 0px;
	&__label {
		font-family: "basier_circleregular", sans-serif;
		font-size: 14px;
		font-weight: 400;
		padding-bottom: 8px;
	}
	&__values {
		font-family: "basier_circleregular", sans-serif;
		font-size: 10px;
		font-weight: 400;
		padding-top: 8px;
		display: flex;
		justify-content: space-between;
	}
}
</style>

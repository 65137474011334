<template>
	<div class="lang-selector">
		<div v-for="(lang, index) in langs" :key="lang.id" class="lang-selector__lang-button">
			<a
				tabindex="1"
				class="lang-selector__lang-button__lang-link"
				:class="{
					active: locale === lang.id,
				}"
				v-on:keyup.enter="langButtonHandler(lang.id)"
				@click="langButtonHandler(lang.id)"
			>
				{{ lang.label }}
			</a>
			<p v-if="index !== langs.length - 1" class="lang-selector__lang-divider"></p>
		</div>
	</div>
</template>

<script setup>
import { UseRootStore } from "@/model/RootStore"

const store = UseRootStore()
const langButtonHandler = lang => {
	store.updateLanguage(lang)
}

defineProps({
	langs: {
		type: Array,
		default: () => [
			{ id: "fr", label: "Fr" },
			{ id: "en", label: "En" },
		],
	},
})
</script>

<style lang="scss">
.lang-selector {
	display: flex;

	&__lang-button {
		font-weight: 500;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		&__lang-link {
			cursor: pointer;
			color: #617179;
		}
	}

	&__lang-divider {
		width: 1px;
		height: 80%;
		margin: auto;
		background-color: #dbdbdb;
		margin-left: 8px;
		margin-right: 8px;
	}
}
</style>

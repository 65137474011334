import axios from "redaxios"

const API_KEY = import.meta.env.VITE_HERE_API_KEY
const BASE_URL = new URL("https://discover.search.hereapi.com/v1/discover")

/* See:
 * https://www.here.com/docs/bundle/geocoding-and-search-api-developer-guide/page/topics/result-types.html
 * for the official list of result types
 */
const ResultType = Object.freeze({
	Place: "place",
	Locality: "locality",
	Street: "street",
	HouseNumber: "houseNumber",
	AdministrativeArea: "administrativeArea",
	AddressBlock: "addressBlock",
	Intersection: "intersection",
	PostalCodePoint: "postalCodePoint",
	ChainQuery: "chainQuery",
	CategoryQuery: "categoryQuery",
})

const REGIONS = Object.freeze([ResultType.Locality, ResultType.AdministrativeArea, ResultType.AddressBlock])

export function isRegion(type) {
	return REGIONS.includes(type)
}

export async function getSuggestions(searchQuery, mapCenter, max = 10) {
	const { lng, lat } = mapCenter
	const url = new URL(BASE_URL)

	url.searchParams.append("q", searchQuery)
	url.searchParams.append("at", `${lat},${lng}`)
	url.searchParams.append("limit", max)
	url.searchParams.append("in", "countryCode:CAN")
	url.searchParams.append("apiKey", API_KEY)

	const res = await axios(url.toString())
	return res.data.items
}

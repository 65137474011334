<template>
	<HorizontalTab v-model="selectedTab" :items="tabs" />
	<layers-selector
		v-if="selectedTab === 'layers'"
		:current-map-style="currentMapStyle"
		@current-map-style="$emit('current-map-style', $event)"
	/>
	<listings-filters v-if="selectedTab === 'filters'" @update-filters="updateListingFilters" />
</template>

<script setup>
import HorizontalTab from "@/components/menu-left/HorizontalTab.vue"
import LayersSelector from "@/components/menu-left/filtering/LayersSelector.vue"
import ListingsFilters from "@/components/menu-left/filtering/ListingsFilters.vue"
import { ref, toRef } from "vue"

const props = defineProps({
	currentMapStyle: {
		type: String,
		required: true,
	},
})
const currentMapStyle = toRef(props, "currentMapStyle")

const emit = defineEmits(["updateFilters", "current-map-style"])
const tabs = [{ id: "filters" }, { id: "layers" }]
const selectedTab = ref("filters")
function updateListingFilters(filters) {
	emit("updateFilters", filters)
}
</script>

<style lang="scss" scoped></style>

import { createI18n } from "vue-i18n"
import messages from "./messages"

const SUPPORTED_LANGUAGES = Object.freeze(["en", "fr"])
const DEFAULT_LANGUAGE = "fr"

const getLocaleFromParam = () => {
	const params = new URLSearchParams(window.location.search)
	return params.has("lang") ? params.get("lang") : null
}

const getLocaleFromStorage = () => {
	return window.localStorage.getItem("lang")
}

const getLocaleFromNavigator = () => {
	const browserLang = navigator.language || navigator.userLanguage
	return browserLang ? browserLang.substring(0, 2) : null
}

const getDefaultLocale = () => {
	const lang = getLocaleFromParam() || getLocaleFromStorage() || getLocaleFromNavigator()
	return lang && SUPPORTED_LANGUAGES.includes(lang) ? lang : DEFAULT_LANGUAGE
}

export const updatePageMetaInfo = (title, description) => {
	document.title = title
	const metaDesc = document.querySelector('meta[name="description"]')
	if (metaDesc) {
		metaDesc.setAttribute("content", description)
	}
}

const i18n = createI18n({
	allowComposition: true,
	legacy: false,
	locale: getDefaultLocale(),
	fallbackLocale: DEFAULT_LANGUAGE,
	messages,
})

updatePageMetaInfo(i18n.global.t("meta-title"), i18n.global.t("meta-description"))

export default i18n

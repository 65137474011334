import { ref, Ref } from "vue"
import { defineStore } from "pinia"
import { Prospect, DevelopmentPotential } from "@/model/DataModel.ts"

export const useProspectStore = defineStore("prospect", () => {
	// State
	const prospects: Ref<Prospect[]> = ref([])

	// Actions
	function updateByParcelId(id: string, partial: Partial<Prospect>): Prospect {
		let prospect: Prospect | null = null

		// Find existing prospect
		prospect = prospects.value.find(p => p.prospectID === id) || null

		if (prospect) {
			// Update found prospect
			Object.assign(prospect, partial)
		} else {
			// Create new prospect object
			prospect = {
				prospectID: id,
				potential: DevelopmentPotential.Unspecified,
			}
			prospects.value.push(Object.assign(prospect, partial))
		}

		return prospect
	}

	return {
		prospects,
		updateByParcelId,
	}
})

<template>
	<div class="list-item" @click="toggle">
		<div class="list-label"><slot></slot></div>
		<div
			class="switch-container"
			:class="{ enabled }"
			:style="{
				'--background-color': backgroundColor,
			}"
		>
			<div class="slider"></div>
		</div>
	</div>
</template>

<script>
import { ref } from "vue"
export default {
	props: {
		id: {
			type: String,
			default: "test",
		},
		value: {
			type: [Boolean, Array],
			default: false,
		},
		backgroundColor: {
			type: String,
			default: "#1BBF73",
		},
	},
	emits: ["input"],
	setup(props, { emit }) {
		const enabled = ref(typeof props.value === "boolean" ? props.value : props.value.includes(this.id))

		const toggle = () => {
			enabled.value = !enabled.value
			if (typeof props.value === "boolean") {
				emit("input", enabled.value)
			} else {
				emit("input", enabled.value ? [...props.value, props.id] : props.value.filter(item => item !== props.id))
			}
		}

		return { enabled, toggle }
	},
}
</script>

<style lang="scss" scoped>
.list-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.list-label {
	color: #191716;
	font-family: "basier_circleregular", sans-serif;
	font-weight: 400;
	font-size: 14px;
}
.switch-container {
	--background-color: #1bbf73;
	cursor: pointer;
	position: relative;
	width: 38px;
	height: 20px;
	border-radius: 34px;
	background-color: #777777;
	-webkit-transition: 0.2s;
	.slider {
		position: absolute;
		height: 16px;
		width: 16px;
		left: 2px;
		bottom: 2px;
		border-radius: 8px;
		background-color: #ffffff;
		transition: left 0.2s ease-out;
	}
	&.enabled {
		background-color: var(--background-color);
		.slider {
			left: 20px;
		}
	}
}
</style>
